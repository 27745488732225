import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './article-preview.module.css'

class ArticlePreview extends React.Component {
  render() {
    const { article } = this.props

    return (
      <div className={styles.previewContainer}>
        <Link style={{ textDecoration: 'none' }} to={`/events/${article.briefDescription}`}>
          <div className={styles.imageContainer}>
            <Img
              className={styles.previewImage}
              fluid={article.eventImage.fluid}
            />
          </div>
          <h3 className={styles.previewTitle}>
            {article.title}
          </h3>
          <small className={styles.previewDate}>{article.eventDate}</small>
          <p className={styles.tag}>{article.briefDescription}</p>
          <div className={styles.readMore}>Read More
            <span className={styles.readMoreArrow}>
              arrow_right_alt
            </span>
          </div>
        </Link>
      </div>
    )
  }
}

export default ArticlePreview
